import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import PageTitle from "@components/admin/PageTitle";
import CompletedPickupTransactionsWithinAreaCountDialogComponent from "@components/admin/transactions/CompletedTransactionsWithinAreaCountDialogComponent";
import TransactionListComponent from "@components/admin/transactions/recent/TransactionListComponent";
import TransactionsInteractionsTable from "@components/admin/transactions/TransactionsInteractionsTable";
import { queryApi } from "@hooks/api";
import { Box, Button } from "@material-ui/core";
import { useState } from "react";

export default withAdminMenuLayout(function RecentTransactionsPageComponent() {

    const [shippingStates] = queryApi(async (options) =>
        await adminClient().adminTransactionsAllValidShippingStatesJsonGet(options),
        []);

    const [dialogOpen, setDialogOpen] = useState(false);

    function handleOpen() {
        setDialogOpen(true);
    }

    return <>
        <PageTitle title="Transactions" />
        {shippingStates && <Box>
            <TransactionListComponent states={shippingStates.states} />
        </Box>}
        <Button
            onClick={handleOpen}
            variant="contained"
            color="secondary"
        >
            Completed transactions within area
        </Button>
        <CompletedPickupTransactionsWithinAreaCountDialogComponent
            confirmationDialogOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
        />
        <TransactionsInteractionsTable/>
    </>
});