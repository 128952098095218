import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { performAction } from "@utils/actions";

export function UnexpectedShippingStateConfirmationDialogsComponent(props: {
	transactionId?: string,
	isEnableDialogOpen: boolean,
	closeEnableDialog: () => void,
	isDisableDialogOpen: boolean,
	closeDisableDialog: () => void,
	isRemoveDialogOpen: boolean,
	closeRemoveDialog: () => void
}) {

	async function reEnableAutoUpdates(transactionId?: string) {
		if (!transactionId) {
			return;
		}
		await performAction(
			() => adminClient().adminTransactionsShippingStateAutoUpdatePost(transactionId, {
				body: {
					isDisable: false
				}
			}),
			"Failed to re-enable automatic shipping updates",
			true);
	}

	async function disableAutoUpdates(transactionId?: string) {
		if (!transactionId) {
			return;
		}
		await performAction(
			() => adminClient().adminTransactionsShippingStateAutoUpdatePost(transactionId, {
				body: {
					isDisable: true
				}
			}),
			"Failed to disable automatic shipping updates",
			true);
	}

	async function removeFromList(transactionId?: string) {
		if (!transactionId) {
			return;
		}
		await performAction(
			() => adminClient().adminTransactionsInUnexpectedShippingStatePost(transactionId, {
				body: {
					isInUnexpectedShippingState: false
				}
			}),
			"Failed to remove from list",
			true);
	}

	return <>
		<ConfirmationDialogComponent
			isDialogOpen={props.isEnableDialogOpen}
			onClose={props.closeEnableDialog}
			onConfirm={() => reEnableAutoUpdates(props.transactionId)}
			title="Confirm re-enabling automatic shipping updates"
			description="Are you sure you want to re-enable automatic shipping updates for transaction?"
		/>
		<ConfirmationDialogComponent
			isDialogOpen={props.isDisableDialogOpen}
			onClose={props.closeDisableDialog}
			onConfirm={() => disableAutoUpdates(props.transactionId)}
			title="Confirm disabling automatic shipping updates"
			description="Are you sure you want to disable automatic shipping updates for transaction?"
		/>
		<ConfirmationDialogComponent
			isDialogOpen={props.isRemoveDialogOpen}
			onClose={props.closeRemoveDialog}
			onConfirm={() => removeFromList(props.transactionId)}
			title="Confirm remove from unexpected shipping states list"
			description="Are you sure you want to remove transaction from unexpected shipping states?"
		/>
	</>
}

