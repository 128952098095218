import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import { convertMaterialUiPickersDateToDate } from "@utils/miscellaneous";
import { addDays, endOfDay, endOfMonth, endOfYear, isAfter, isBefore, startOfDay, startOfMonth, subDays, subMonths, subYears } from "date-fns";
import { startOfYear } from "date-fns/esm";
import { isEqual } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export default function DateRangePickerComponent(props: {
	onSubmit?: (fromDate: Date | null, toDate: Date | null) => void,
	renderTimeOption: boolean,
	renderDateRanges: boolean,
	disableFutureDates: boolean,
	disablePastDates: boolean,
	submitOnLoad: boolean,
	variant: "standard" | "outlined" | "filled"
	defaultFromDate?: Date,
	updateFromDateOnParent?: Dispatch<SetStateAction<Date | null>>,
	defaultToDate?: Date,
	updateToDateOnParent?: Dispatch<SetStateAction<Date | null>>,
	fromDateLabel?: string,
	toDateLabel?: string
}) {
	const startOfToday = startOfDay(new Date());
	const endOfToday = endOfDay(new Date());

	const dateRanges: Array<DateRange> = [
		{
			label: "Custom",
			fromDate: null,
			toDate: null
		},
		{
			label: "Today",
			fromDate: startOfToday,
			toDate: endOfToday
		},
		{
			label: "Last 7 Days",
			fromDate: subDays(startOfToday, 6),
			toDate: endOfToday
		},
		{
			label: "Last 30 Days",
			fromDate: subDays(startOfToday, 29),
			toDate: endOfToday
		},
		{
			label: "Last 90 Days",
			fromDate: subDays(startOfToday, 89),
			toDate: endOfToday
		},
		{
			label: "Last 180 Days",
			fromDate: subDays(startOfToday, 179),
			toDate: endOfToday
		},
		{
			label: "Last 365 Days",
			fromDate: subDays(startOfToday, 364),
			toDate: endOfToday
		},
		{
			label: "This Month",
			fromDate: startOfMonth(new Date()),
			toDate: endOfToday
		},
		{
			label: "Last Month",
			fromDate: subMonths(startOfMonth(startOfToday), 1),
			toDate: subMonths(endOfMonth(endOfToday), 1)
		},
		{
			label: "This Year",
			fromDate: startOfYear(startOfToday),
			toDate: endOfToday
		},
		{
			label: "Last Year",
			fromDate: subYears(startOfYear(startOfToday), 1),
			toDate: subYears(endOfYear(endOfToday), 1)
		},
		{
			label: "Since November 2015",
			fromDate: new Date(2015, 11, 1),
			toDate: endOfToday
		}
	];

	const getDateRangeIndexFromDates = (from: Date | undefined, to: Date | undefined): number => {
		if (!from || !to) {
			return 8;
		}
		let dateRange = dateRanges.findIndex(dateRange => isEqual(dateRange.fromDate, from) && isEqual(dateRange.toDate, to));
		if (dateRange === -1) {
			return 0;
		}
		return dateRange;
	}

	const [selectedDateRangeIndex, setSelectedDateRangeIndex] = useState<number>(getDateRangeIndexFromDates(props.defaultFromDate, props.defaultToDate));

	const [fromDate, setFromDate] = useState<Date>(props.defaultFromDate ?? dateRanges[selectedDateRangeIndex].fromDate!);
	const [toDate, setToDate] = useState<Date>(props.defaultToDate ?? dateRanges[selectedDateRangeIndex].toDate!);

	useEffect(() => {

		if (props.updateFromDateOnParent) {
			 props.updateFromDateOnParent(fromDate);
		}

		if (props.updateToDateOnParent) {
			 props.updateToDateOnParent(toDate);
		}
  }, [fromDate, props, toDate]);

	function handleDateRangeChange(dateRange: DateRange) {
		let providerIndex = dateRanges.indexOf(dateRange);
		setSelectedDateRangeIndex(providerIndex);
		setFromDate(dateRange.fromDate ?? fromDate);
		setToDate(dateRange.toDate ?? toDate);
	}

	const handleFromChange = (newFromDate: Date | null) => {
		if (!newFromDate) {
			return;
		}
		if (props.renderTimeOption) {
			handleFromDateTimeChange(newFromDate);
		} else {
			handleFromDateChange(newFromDate);
		}
	}

	const handleFromDateTimeChange = (newFromDate: Date) => {
		if (isEqual(newFromDate, fromDate)) {
			return;
		}
		if (isBefore(newFromDate, new Date())) {
			newFromDate = new Date();
		}
		if (isAfter(newFromDate, toDate)) {
			setToDate(addDays(newFromDate, 1));
		}
		setFromDate(newFromDate);
		setSelectedDateRangeIndex(getDateRangeIndexFromDates(newFromDate, toDate));
	}

	const handleFromDateChange = (newFromDate: Date) => {
		let startOfNewFromDate = startOfDay(newFromDate);
		if (isEqual(startOfNewFromDate, fromDate)) {
			return;
		}
		if (isAfter(startOfNewFromDate, toDate)) {
			setToDate(endOfDay(newFromDate));
		}
		setFromDate(startOfNewFromDate);
		setSelectedDateRangeIndex(getDateRangeIndexFromDates(startOfNewFromDate, toDate));
	}

	const handleToChange = (newToDate: Date | null) => {
		if (!newToDate) {
			return;
		}
		if (props.renderTimeOption) {
			handleToDateTimeChange(newToDate);
		} else {
			handleToDateChange(newToDate);
		}

	}

	const handleToDateTimeChange = (newToDate: Date) => {
		if (isEqual(newToDate, toDate)) {
			return;
		}
		if (isBefore(newToDate, fromDate)) {
			let oneDayBeforeTo = subDays(newToDate, 1);
			setFromDate(isBefore(oneDayBeforeTo, new Date()) ? new Date : oneDayBeforeTo);
		}
		if (isBefore(newToDate, new Date())) {
			newToDate = new Date();
		}
		setToDate(newToDate);
		setSelectedDateRangeIndex(getDateRangeIndexFromDates(fromDate, newToDate));
	}

	const handleToDateChange = (newToDate: Date) => {
		if (isEqual(newToDate, toDate)) {
			return;
		}
		if (isBefore(newToDate, fromDate)) {
			setFromDate(startOfDay(newToDate));
		}
		setToDate(endOfDay(newToDate));
		setSelectedDateRangeIndex(getDateRangeIndexFromDates(fromDate, endOfDay(newToDate)));
	}

	type DateRange = {
		label: string;
		fromDate: Date | null;
		toDate: Date | null;
	};

	let fromLabel = props.fromDateLabel ?? "From";
	let toLabel = props.toDateLabel ?? "To";

	if (props.submitOnLoad && props.onSubmit) {
		props.onSubmit(fromDate, toDate);
	}

	return <>
		<Box
			className="date-range-picker"
			style={{
				position: 'relative'
			}}
		>
			{props.renderTimeOption ?
				<>
					<FormControl
						style={{
							marginRight: 16
						}}
					>
						<DateTimePicker
							style={{ maxWidth: "132px" }}
							autoOk
							ampm={false}
							inputVariant={props.variant}
							disableFuture={props.disableFutureDates}
							disablePast={props.disablePastDates}
							variant="inline"
							value={fromDate}
							onChange={(e) => handleFromChange(convertMaterialUiPickersDateToDate(e))}
							label={fromLabel}
							format="Pp"
						/>
					</FormControl>
					<FormControl
						style={{
							marginRight: 16
						}}
					>
						<DateTimePicker
							style={{ maxWidth: "132px" }}
							autoOk
							ampm={false}
							inputVariant={props.variant}
							disableFuture={props.disableFutureDates}
							disablePast={props.disablePastDates}
							variant="inline"
							value={toDate}
							onChange={(e) => handleToChange(convertMaterialUiPickersDateToDate(e))}
							label={toLabel}
							format="Pp"
						/>
					</FormControl>
				</>
				:
				<>
					<FormControl
						style={{
							marginRight: 16
						}}
					>
						<DatePicker
							style={{ maxWidth: "114px" }}
							autoOk
							inputVariant={props.variant}
							disableFuture={props.disableFutureDates}
							disablePast={props.disablePastDates}
							variant="inline"
							value={fromDate}
							onChange={(e) => handleFromChange(convertMaterialUiPickersDateToDate(e))}
							label={fromLabel}
							format="P"
						/>
					</FormControl>
					<FormControl
						style={{
							marginRight: 16
						}}
					>
						<DatePicker
							style={{ maxWidth: "114px" }}
							autoOk
							inputVariant={props.variant}
							disableFuture={props.disableFutureDates}
							disablePast={props.disablePastDates}
							variant="inline"
							value={toDate}
							onChange={(e) => handleToChange(convertMaterialUiPickersDateToDate(e))}
							label={toLabel}
							format="P"
						/>
					</FormControl>
				</>
			}
			{!props.renderDateRanges ?
				<></> :
				<FormControl
					style={{
						marginRight: 16
					}}
				>
					<InputLabel>
						Date ranges
					</InputLabel>
					<Select
						style={{ minWidth: "184px" }}
						native={false}
						value={dateRanges[selectedDateRangeIndex]}
						onChange={(event) => handleDateRangeChange(event.target.value as DateRange)}
						fullWidth={true}
					>
						{dateRanges?.map(dateRange =>
							<MenuItem
								value={dateRange as any}
								key={dateRange.label}>
								{dateRange.label}
							</MenuItem>)
						}
					</Select>
				</FormControl>}
				<FormControl>
					{props.onSubmit &&
					<Button
						onClick={() => props.onSubmit != undefined && props.onSubmit(fromDate, toDate)}
						variant="contained"
						color="primary"
						size="large"
					>
						Submit
					</Button> }
				</FormControl>
		</Box>
	</>
	
}

