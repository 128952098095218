import { adminClient } from "@api/admin/AdminClient";
import EditValueDialogComponent from "@components/admin/EditValueDialogComponent";
import { TableCell, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { performAction } from "@utils/actions";
import { useState } from "react";

export function EditAdminNoteTableCellComponent(props: {
	transactionId: string,
	adminNote?: string
}) {
	const [isAdminNoteDialogOpen, setIsAdminNoteDialogOpen] = useState(false);
	const [currentlySelectedAdminNote, setCurrentlySelectedAdminNote] = useState<string>();
	const [currentlySelectedTransactionId, setCurrentlySelectedTransactionId] = useState<string>();

	const useStyles = makeStyles(() =>
		createStyles({
			container: {
				display: "inline-block",
				width: "200px",
				height: "50px"
			},
			item: {
				display: "inline-block",
				paddingRight: "2px",
			},
			adminNoteEdit: {
				textDecoration: 'none',
				'&:hover': {
					textDecoration: "underline #154da9"
				}
			}
		}
		),
	);

	const classes = useStyles();

	async function editAdminNoteAction(adminNote?: string) {
		if (!currentlySelectedTransactionId) {
			return;
		}

		adminNote = !adminNote ? undefined : adminNote;

		await performAction(
			() => adminClient().adminEditTransactionNotePost(
				{
					body: {
						transactionId: currentlySelectedTransactionId,
						adminNote
					}
				}
			),
			"Failed to edit admin note");
	}

	function renderAdminNoteLink() {
		return <Typography
			className={classes.adminNoteEdit}
			onClick={() => {
				setCurrentlySelectedTransactionId(props.transactionId);
				setCurrentlySelectedAdminNote(props.adminNote);
				setIsAdminNoteDialogOpen(true);
			}}
			style={{ cursor: "pointer" }}
			color="primary"
		>
			{!props.adminNote ? "Add note" : "Edit note"}
		</Typography>;
	}

	return <>
		<TableCell>
			{!!props.adminNote ?
				<Tooltip title={props.adminNote}>
					{renderAdminNoteLink()}
				</Tooltip> :
				renderAdminNoteLink()}
		</TableCell>
		<EditValueDialogComponent
			isDialogOpen={isAdminNoteDialogOpen}
			onClose={() => {
				setIsAdminNoteDialogOpen(false)
				setCurrentlySelectedTransactionId(undefined);
				setCurrentlySelectedAdminNote(undefined);
			}}
			onSubmit={editAdminNoteAction}
			title={"Edit admin note"}
			defaultValue={currentlySelectedAdminNote}
		/>
	</>;
}
