import { Button, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		lessPaddingTitle: {
			paddingBottom: "2px",
		},
		lessPaddingContent: {
			paddingTop: "2px",
			paddingBottom: "2px",
		},
		modal: {
			maxWidth: "100%",
			maxHeight: "100%",
			display: "flex",
			justifyContent: "center"
		}
	}),
);

export default function InformationDialogComponent(props: {
	isDialogOpen: boolean;
	onDismiss?: () => void;
	dialogTitle: string | undefined;
	dialogContent?: string | (() => JSX.Element)
}) {
	const classes = useStyles();
	return <Dialog
		className={classes.modal}
		open={props.isDialogOpen}
		onClose={() => props.onDismiss && props.onDismiss()}
		aria-labelledby="alert-dialog-slide-title"
		aria-describedby="alert-dialog-slide-description"
	>
		<DialogTitle className={classes.lessPaddingTitle}>
			{props.dialogTitle}
		</DialogTitle>
		{props.dialogContent &&
			<DialogContent className={classes.lessPaddingContent}>
				{typeof props.dialogContent === "string" ?
					<DialogContentText id="alert-dialog-slide-description">
						{props.dialogContent}
					</DialogContentText> :
					props.dialogContent()}
			</DialogContent>
		}
		<DialogActions>
			<Button onClick={() => props.onDismiss && props.onDismiss()} color="primary">
				OK
			</Button>
		</DialogActions>
	</Dialog>
}