import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import InformationDialogComponent from "@components/admin/InformationDialogComponent";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@material-ui/core";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function CompletedPickupTransactionsWithinAreaCountDialogComponent(
    props: {
        confirmationDialogOpen: boolean,
        onClose: () => void
    }
): JSX.Element {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isRadiusValid, setIsRadiusValid] = useState(true);

    const [fromUtc, setFromUtc] = useState<Date | null>(null);
	const [toUtc, setToUtc] = useState<Date | null>(null);

    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipcode] = useState("");
    const [radiusInKilometers, setRadiusInKilometers] = useState(5);

    const [completedPickupTransactionsCount, setcompletedPickupTransactionsCount] = useState<number>();


    const getcompletedPickupTransactionsCountWithinArea = async () => {
        if (!fromUtc || !toUtc) {
            return;
        }

        await performAction(
            async () => {
                const completedPickupTransactionsResponse = await adminClient().adminTransactionsCompletedPickupWithinAreaCountGet({
                    body: {
                        street,
                        city,
                        zipCode,
                        radiusInKilometers,
                        fromUtc,
                        toUtc
                    }
                });
                setcompletedPickupTransactionsCount(completedPickupTransactionsResponse.completedTransactionsWithinAreaCount);
            },
            "Failed to count completed transactions within area",
            false);
        setDialogOpen(true);
    }

    function CompletedPickupTransactionsCountDialogComponent() {
        return <InformationDialogComponent
            isDialogOpen={dialogOpen}
            onDismiss={() => {
                setDialogOpen(false);
            }}
            dialogTitle={completedPickupTransactionsCount + " completed transactions within area"} />;
    }

    return <>
        <Dialog
            open={props.confirmationDialogOpen}
            transitionDuration={0}
            disableAutoFocus={false}
            disableRestoreFocus={false}
        >
            <DialogTitle>
                Get completed transactions count within area
            </DialogTitle>
            <DialogContent>
                <DateRangePickerComponent
                    renderTimeOption={false}
                    renderDateRanges={true}
                    disableFutureDates={true}
                    disablePastDates={false}
                    updateFromDateOnParent={setFromUtc}
                    submitOnLoad={false}
                    updateToDateOnParent={setToUtc}
                    variant="outlined" />
                <FormControl>
                    <TextField
                        id="street"
                        label="Street"
                        value={street}
                        onChange=
                        {(event) => {
                            setStreet(event.target.value);
                        }}
                    />
                    <TextField
                        id="city"
                        label="City"
                        defaultValue={city}
                        onChange=
                        {(event) => {
                            setCity(event.target.value);
                        }}
                    />
                    <TextField
                        id="zipcode"
                        label="Zipcode"
                        defaultValue={zipCode}
                        onChange=
                        {(event) => {
                            setZipcode(event.target.value);
                        }}
                    />
                    <TextField
                        error={!isRadiusValid}
                        helperText={isRadiusValid ? "" : "Radius is not a valid number"}
                        id="radius"
                        label="Radius in km"
                        defaultValue={radiusInKilometers}
                        onChange={(event) => {
                            var radiusInKmAsNumber = +event.target.value;
                            if (isNaN(radiusInKmAsNumber)) {
                                setIsRadiusValid(false);
                                return;
                            }
                            setRadiusInKilometers(radiusInKmAsNumber);
                            setIsRadiusValid(true);
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    variant="outlined"
                    color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={!isRadiusValid}
                    onClick={async () => await getcompletedPickupTransactionsCountWithinArea()}
                    variant="contained"
                    color="primary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        <CompletedPickupTransactionsCountDialogComponent />
    </>

}