import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { EditAdminNoteTableCellComponent } from "@components/admin/transactions/EditAdminNoteTableCellComponent";
import { UnexpectedShippingStateConfirmationDialogsComponent } from "@components/admin/transactions/UnexpectedShippingStateConfirmationDialogsComponent";
import InternalLink from "@components/InternalLink";
import TableComponent, { Header } from "@components/TableComponent";
import TabsControl from "@components/TabsControl";
import WebpImage from "@components/WebpImage";
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, TableCell, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Check } from "@material-ui/icons";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AdminGetTransactionResponse, AdminShippingDetailsResponse, AdminTransactionResponse, AdminTransactionUserSnapshotResponse } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { navigateToExternalUrl } from "@utils/navigation";
import _ from "lodash";
import { useState } from "react";

export default function TransactionListComponent(props: { states: string[] }) {
	const [isEnableDialogOpen, setEnableDialogOpen] = useState(false);
	const [isDisableDialogOpen, setDisableDialogOpen] = useState(false);
	const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
	const [isShippingStateDropdownEnabled, setIsShippingStateDropdownEnabled] = useState(false);
	const [currentlySelectedTransactionId, setCurrentlySelectedTransactionId] = useState<string>();
	const [currentlySelectedShippingState, setCurrentlySelectedShippingState] = useState(props.states[1]);
	const [currentlySelectedTabIndex, setCurrentlySelectedTabIndex] = useState(0);
	const [isInquireDialogOpen, setIsInquireDialogOpen] = useState(false);
	const [currentlySelectedItemCount, setCurrentlySelectedItemCount] = useState<number>();
	const [searchTextValue, setSearchTextValue] = useState<string>("");
	const [isSearchable, setIsSearchable] = useState(false);

	const [updateBringTable, setUpdateBringTable] = useState(false);
	const [updateDaoShopTable, setUpdateDaoShopTable] = useState(false);
	const [updateDaoHomeTable, setUpdateDaoHomeTable] = useState(false);
	const [updateInstaBoxTable, setUpdateInstaBoxTable] = useState(false);

	const shippingStateSupportedTabIndices = [2, 3, 4, 5, 6, 7];
	const searchSupportedTabIndices = [4, 5, 6, 7];

	function onTabChange(tabIndex: number) {
		setCurrentlySelectedTabIndex(tabIndex);
		setSearchTextValue("");
		if (shippingStateSupportedTabIndices.includes(tabIndex)) {
			setIsShippingStateDropdownEnabled(true);
		} else {
			setIsShippingStateDropdownEnabled(false);
		}

		if (searchSupportedTabIndices.includes(tabIndex)) {
			setIsSearchable(true);
		} else {
			setIsSearchable(false);
		}
	}

	function onEnableClick(transactionId: string) {
		setCurrentlySelectedTransactionId(transactionId);
		setEnableDialogOpen(true);
	}

	function closeEnableDialog() {
		setCurrentlySelectedTransactionId(undefined);
		setEnableDialogOpen(false);
	}

	function closeDisableDialog() {
		setCurrentlySelectedTransactionId(undefined);
		setDisableDialogOpen(false);
	}

	function onRemoveClick(transactionId: string) {
		setCurrentlySelectedTransactionId(transactionId);
		setRemoveDialogOpen(true);
	}

	function closeRemoveDialog() {
		setCurrentlySelectedTransactionId(undefined);
		setRemoveDialogOpen(false);
	}

	const submitSearch = () => {


		if (currentlySelectedTabIndex === 4) {
			setUpdateBringTable((oldUpdateBringTable) => !oldUpdateBringTable);
		}

		if (currentlySelectedTabIndex === 5) {
			setUpdateDaoShopTable((oldUpdateDaoShopTable) => !oldUpdateDaoShopTable);
		}

		if (currentlySelectedTabIndex === 6) {
			setUpdateDaoHomeTable((oldUpdateDaoHomeTable) => !oldUpdateDaoHomeTable);
		}

		if (currentlySelectedTabIndex === 7) {
			setUpdateInstaBoxTable((oldUpdateInstaBoxTable) => !oldUpdateInstaBoxTable);
		}

	}

	const useStyles = makeStyles(() =>
		createStyles({
			container: {
				display: "inline-block",
				width: "200px",
				height: "50px"
			},
			item: {
				display: "inline-block",
				paddingRight: "2px",
			},
			adminNoteEdit: {
				textDecoration: 'none',
				'&:hover': {
					textDecoration: "underline #154da9"
				}
			}
		}
		),
	);

	const classes = useStyles();

	function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
		setCurrentlySelectedShippingState(event.target.value as string);
	}

	function handleItemCountChange(event: React.ChangeEvent<{ value: unknown }>) {
		setCurrentlySelectedItemCount(event.target.value as number);
	}

	function renderSelectItemCount(count:number) {
		const rows = [];
		for (let i = 1; i < count; i++) {
			rows.push(<MenuItem value={i}>{i}</MenuItem>);
		}
		
		return <FormControl
		fullWidth={true} style={{
			minWidth: "150px"
			}}>
			<InputLabel>
				Item Count
			</InputLabel>
			<Select
				disabled={!isSearchable}
				native={false}
				value={currentlySelectedItemCount}
				defaultValue={null}
				onChange={handleItemCountChange}
				fullWidth={true}
				labelWidth={5}
			>
			<MenuItem>Any</MenuItem>
			{rows}
		</Select>
	</FormControl>
	}

	function renderTransactionImages(transaction: AdminTransactionResponse) {
		const packageImage = transaction.details?.shipping?.packageImages && transaction.details?.shipping?.packageImages[0];
		const imageSize = 48;
		const nonPackageImageCount = packageImage ? 2 : 3;

		return <Box
			style={{
				display: 'flex',
				flex: 1,
				flexWrap: 'wrap'
			}}
		>
			{packageImage && <WebpImage size={imageSize} images={[packageImage]} style={{
				marginRight: 8
			}} />}
			{_.take(transaction.items, nonPackageImageCount).map(item =>
				<div
					className={classes.item}
					key={"item-" + item.itemId}
					onClick={() => navigateToExternalUrl("/admin/items/details/?id=" + encodeURIComponent(item.itemId), true)}
					style={{
						cursor: 'pointer'
					}}
				>
					<WebpImage size={imageSize} images={[item.image]} />
				</div>)}
		</Box>
	}

	function renderTransactionState(transaction: AdminTransactionResponse) {
		let shippingDetails = transaction.details?.shipping;
		if (!shippingDetails) {
			return "NO STATE"
		}

		return <>
			{evaluateShippingState(shippingDetails)}
			<Box>
				{evaluateStateChangeTimeStamp(shippingDetails, transaction)}
				{renderHandedInChipDays(transaction)}
				{renderInTransitChipDays(transaction)}
			</Box>
		</>
	}

	function getTransactionBrands(transaction: AdminTransactionResponse) {
		let items = transaction.items;
		let result = "";
		for (let index = 0; index < items.length; index++) {
			result += (items[index].brandOrTitle)
			if (index !== items.length - 1) {
				result += ", "
			}
		}
		return result;
	}

	function evaluateShippingState(shippingDetails: AdminShippingDetailsResponse) {
		let status = shippingDetails.status || "NO SHIPPING STATE";

		return <>
			<Typography style={{ display: 'inline-block' }}>{status}</Typography>
			{status === props.states[0] &&
				<Check
					color="primary"
					fontSize="small"
					style={{
						marginLeft: 4
					}}
				/>}
		</>;
	}

	function evaluateStateChangeTimeStamp(shippingDetails: AdminShippingDetailsResponse, transaction: AdminTransactionResponse) {
		let acceptedUtc = transaction.acceptedUtc;
		if (acceptedUtc && shippingDetails.status?.toString() === "PRE_NOTIFIED") {
			return toDanishDateFormat(acceptedUtc);
		}

		let completedUtc = transaction.completedUtc;
		if (completedUtc && shippingDetails.status?.toString() === "DELIVERED") {
			return toDanishDateFormat(completedUtc);
		}

		let shippedAtUtc = shippingDetails.shippedAtUtc;
		if (shippedAtUtc !== null) {
			return toDanishDateFormat(shippedAtUtc);
		}
	}

	function renderHandedInChipDays(transaction: AdminTransactionResponse) {
		let shippingDetails = transaction.details.shipping;
		if (!shippingDetails) {
			throw new Error("Expected shipping details to be present.");
		}

		let shippedAtUtc = shippingDetails.shippedAtUtc;
		if (shippingDetails.status !== props.states[1] || shippedAtUtc === null) {
			return;
		}

		let now = new Date().valueOf();
		let shippedAtUtcAsNumber = new Date(shippedAtUtc as any).valueOf();
		let daysSpent = Math.floor(((now - shippedAtUtcAsNumber) / (60 * 60 * 24 * 1000)));
		if (daysSpent <= 2) {
			return null;
		}

		return <Chip
			size="small"
			label={daysSpent + "d"}
			variant="outlined"
			color="primary"
			key={"tag" + transaction.id}
			style={{
				marginLeft: 8
			}}
		/>
	}

	function renderInTransitChipDays(transaction: AdminTransactionResponse) {
		let shippingDetails = transaction.details.shipping;
		if (!shippingDetails) {
			throw new Error("Expected shipping details to be present.");
		}

		let inTransitStateUtc = shippingDetails.lastStateUpdateUtc;
		if (shippingDetails.status !== props.states[2] || inTransitStateUtc === null) {
			return;
		}

		let now = new Date().valueOf();

		let inTransitStateUtcAsNumber = (inTransitStateUtc as any).valueOf();
		let daysSpent = Math.floor(((now - inTransitStateUtcAsNumber) / (60 * 60 * 24 * 1000)));
		if (daysSpent <= 2) {
			return null;
		}

		return <Chip
			size="small"
			label={daysSpent + "d"}
			variant="outlined"
			color="primary"
			key={"tag" + transaction.id}
			style={{
				marginLeft: 8
			}}
		/>
	}

	function evaluateAdditionalBookingLabels(transaction: AdminTransactionResponse) {
		let additionalTransactionLabelBookings = transaction.details?.shipping?.additionalTransactionLabelBookings;
		if (!additionalTransactionLabelBookings?.length)
			return;

		return additionalTransactionLabelBookings.forEach(
			additionalTransactionLabelBooking => <>
				<Box>
					<InternalLink href={additionalTransactionLabelBooking.trackingUrl}>
						{(additionalTransactionLabelBooking.trackingUrl ||
							additionalTransactionLabelBooking.trackingUrl !== "") ?
							additionalTransactionLabelBooking.packageNumber : "NO TRACKINGURL"}
					</InternalLink>
				</Box>
			</>)
	}

	function renderBookingNumbers(transaction: AdminTransactionResponse) {
		let transactionLabelBooking = transaction.details?.shipping?.transactionLabelBooking;
		return <>
			<Box>
				<Box>
					<InternalLink href={"/admin/transactions/details/?id=" + encodeURIComponent(transaction.id)}>
						{transactionLabelBooking?.packageLabel}
					</InternalLink>
				</Box>
				<Box>
					<InternalLink href={transactionLabelBooking?.trackingUrl}>
						{transactionLabelBooking?.packageNumber}
					</InternalLink>
				</Box>
			</Box>
			{evaluateAdditionalBookingLabels(transaction)}
		</>
	}

	function RenderTransactionParticipantInfo(props: {
		transactionUserSnapshot?: AdminTransactionUserSnapshotResponse,
		addressNeeded: boolean
	}
	): JSX.Element {
		return <TableCell>
			<Box>
				<InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(props.transactionUserSnapshot?.id ?? "")}>
					{props.transactionUserSnapshot?.publicName ?? "Unknown"}
				</InternalLink>
			</Box>
			{props.addressNeeded &&
				props.transactionUserSnapshot?.address?.city
				? props.transactionUserSnapshot.address?.zipCode + " " + props.transactionUserSnapshot.address?.city
				: "PARTICIPANT ADDRESS MISSING"}
		</TableCell>
	}

	function renderInUnexpectedShippingStateTransactionRow(transaction: AdminGetTransactionResponse) {
		let transactionLabelBooking = transaction.details!.shipping!.transactionLabelBooking;
		const shippingDetails = transaction.details?.shipping;
		return <>
			<TableCell>
				<InternalLink
					href={"/admin/transactions/details/?id=" + encodeURIComponent(transaction.id)}
				>
					{transaction.id}
				</InternalLink>
			</TableCell>
			<TableCell>
				{transaction.buyer && <InternalLink
					href={"/admin/users/details/?id=" + encodeURIComponent(transaction.buyer.id)}
				>
					{transaction.buyer?.publicName}, {transaction.buyer.address?.zipCode}, {transaction.buyer.address?.city}
				</InternalLink>}
			</TableCell>
			<TableCell>
				{transaction.seller && <InternalLink
					href={"/admin/users/details/?id=" + encodeURIComponent(transaction.seller.id)}
				>
					{transaction.seller?.publicName}, {transaction.seller.address?.zipCode}, {transaction.seller.address?.city}
				</InternalLink>}
			</TableCell>
			<TableCell>{toDanishDateFormat(shippingDetails?.shippedAtUtc, "Pp")}</TableCell>
			<TableCell>{!!shippingDetails?.shippingStateAutoUpdateDisabledAtUtc ? toDanishDateFormat(shippingDetails?.shippingStateAutoUpdateDisabledAtUtc, "Pp") : "Not disabled"}</TableCell>
			<TableCell><InternalLink
				onClick={() => navigateToExternalUrl(transactionLabelBooking!.trackingUrl, true)}
			>
				{transactionLabelBooking?.packageLabel}
			</InternalLink>
			</TableCell>
			<TableCell>{shippingDetails?.status}</TableCell>
			<EditAdminNoteTableCellComponent
				transactionId={transaction.id}
				adminNote={transaction.adminNote}
			/>
			<TableCell>
				<Button
					variant="contained"
					color="primary"
					onClick={() => onEnableClick(transaction.id)}
				>
					Enable
				</Button>
			</TableCell>
			<TableCell>
				{!!shippingDetails?.inUnexpectedShippingStateAtUtc &&
					!shippingDetails?.shippingStateAutoUpdateDisabledAtUtc &&
					<Box
						onClick={() => onRemoveClick(transaction.id)}
						style={{ cursor: "pointer" }}>
						<HighlightOffIcon color="error" />
					</Box>}
			</TableCell>
		</>;
	}

	function renderDelayedTransactionRow(transaction: AdminTransactionResponse) {
		let shippingDetails = transaction.details?.shipping;
		if (!shippingDetails) {
			throw new Error("Expected shipping details to be present.");
		}

		return <>
			<RenderTransactionParticipantInfo
				transactionUserSnapshot={transaction.sellerSnapshot}
				addressNeeded={true}
			/>
			<RenderTransactionParticipantInfo
				transactionUserSnapshot={transaction.buyerSnapshot}
				addressNeeded={true}
			/>
			<TableCell>
				{renderTransactionState(transaction)}
			</TableCell>
			<TableCell>
				{shippingDetails.transactionLabelBooking ?
					renderBookingNumbers(transaction) :
					"No Bookings"}
			</TableCell>
			<TableCell>
				{toDanishDateFormat(shippingDetails.lastInquiryUtc)}
			</TableCell>
			<EditAdminNoteTableCellComponent
				transactionId={transaction.id}
				adminNote={transaction.adminNote}
			/>
			<TableCell>
				<Button
					onClick={() => setIsInquireDialogOpen(true)}
					variant="contained"
					color="primary">
					Inquire
				</Button>
			</TableCell>
			<ConfirmationDialogComponent
				isDialogOpen={isInquireDialogOpen}
				onClose={() => setIsInquireDialogOpen(false)}
				onConfirm={() => performAction(
					() => adminClient().adminTransactionsBeginInquiryPost(transaction.id),
					"Failed to begin inquiry")}
				title="Confirm beginning inquiry"
				description="Are you sure you want begin inquiry for this transaction?"
			/>
		</>;
	}

	function renderShippingTransactionRow(transaction: AdminTransactionResponse) {
		let shippingDetails = transaction.details?.shipping;
		if (!shippingDetails)
			throw new Error("Expected shipping details to be present.");

		return <>
			<TableCell>
				{renderTransactionImages(transaction)}
			</TableCell>
			<TableCell>
				{getTransactionBrands(transaction)}
			</TableCell>
			<RenderTransactionParticipantInfo
				transactionUserSnapshot={transaction.sellerSnapshot}
				addressNeeded={true}
			/>
			<RenderTransactionParticipantInfo
				transactionUserSnapshot={transaction.buyerSnapshot}
				addressNeeded={true}
			/>
			<TableCell>
				{renderTransactionState(transaction)}
			</TableCell>
			<TableCell>
				{shippingDetails.transactionLabelBooking ?
					renderBookingNumbers(transaction) :
					"No Bookings"}
			</TableCell>
			<TableCell>
				{toDanishDateFormat(shippingDetails.lastInquiryUtc)}
			</TableCell>
			<EditAdminNoteTableCellComponent
				transactionId={transaction.id}
				adminNote={transaction.adminNote}
			/>
		</>;
	}

	function renderPickupTransactionRow(transaction: AdminTransactionResponse) {
		return <>
			<TableCell>
				{renderTransactionImages(transaction)}
			</TableCell>
			<TableCell>
				{getTransactionBrands(transaction)}
			</TableCell>
			<RenderTransactionParticipantInfo
				transactionUserSnapshot={transaction.sellerSnapshot}
				addressNeeded={false}
			/>
			<RenderTransactionParticipantInfo
				transactionUserSnapshot={transaction.buyerSnapshot}
				addressNeeded={false}
			/>
			<TableCell>
				{toDanishDateFormat(transaction.createdUtc)}
			</TableCell>
			<TableCell>
				{toDanishDateFormat(transaction.acceptedUtc)}
			</TableCell>
			<TableCell>
				<InternalLink href={"/admin/transactions/details/?id=" + encodeURIComponent(transaction.id)}>
					{transaction.id ? "DETAILS" : "ID MISSING"}
				</InternalLink>
			</TableCell>
			<EditAdminNoteTableCellComponent
				transactionId={transaction.id}
				adminNote={transaction.adminNote}
			/>
		</>;
	}

	const unexpectedShippingStateTransactionHeaders = (): Array<Header<AdminGetTransactionResponse>> => [
		{ databaseSortingKey: null, label: "ID" },
		{ databaseSortingKey: null, label: "Buyer" },
		{ databaseSortingKey: null, label: "Seller" },
		{ databaseSortingKey: x => x.details?.shipping?.shippedAtUtc, label: "Shipped" },
		{ databaseSortingKey: x => x.details?.shipping?.shippingStateAutoUpdateDisabledAtUtc, label: "Updates disabled", isDefault: true },
		{ databaseSortingKey: null, label: "Package Label" },
		{ databaseSortingKey: null, label: "Shipping State" },
		{ databaseSortingKey: null, label: "Admin note" },
		{ databaseSortingKey: null, label: "Auto updates" },
		{ databaseSortingKey: null, label: "Remove?" }
	];

	const delayedTransactionHeaders: Array<Header<AdminTransactionResponse>> = [
		{ databaseSortingKey: x => x.sellerSnapshot.publicName, label: "Seller" },
		{ databaseSortingKey: x => x.buyerSnapshot?.publicName, label: "Buyer" },
		{ databaseSortingKey: x => x.details.shipping?.lastStateUpdateUtc, label: "State", isDefault: true },
		{ databaseSortingKey: null, label: "Numbers" },
		{ databaseSortingKey: x => x.details.shipping?.lastInquiryUtc, label: "Inquired" },
		{ databaseSortingKey: null, label: "Admin note" },
		{ databaseSortingKey: null, label: "Admdn note" }
	];

	const shippingTransactionHeaders: Array<Header<AdminTransactionResponse>> = [
		{ databaseSortingKey: null, label: "Items", width: 400 },
		{ databaseSortingKey: null, label: "Brands" },
		{ databaseSortingKey: x => x.sellerSnapshot.publicName, label: "Seller" },
		{ databaseSortingKey: x => x.buyerSnapshot?.publicName, label: "Buyer" },
		{ databaseSortingKey: x => x.acceptedUtc, label: "State", isDefault: true },
		{ databaseSortingKey: null, label: "Numbers" },
		{ databaseSortingKey: x => x.details.shipping?.lastInquiryUtc, label: "Last Inquiry" },
		{ databaseSortingKey: null, label: "Admin note" }
	];

	const pickupTransactionHeaders: Array<Header<AdminTransactionResponse>> = [
		{ databaseSortingKey: null, label: "Items", width: 400 },
		{ databaseSortingKey: null, label: "Brands" },
		{ databaseSortingKey: x => x.sellerSnapshot.publicName, label: "Seller" },
		{ databaseSortingKey: x => x.buyerSnapshot?.publicName, label: "Buyer" },
		{ databaseSortingKey: x => x.createdUtc, label: "Created", isDefault: true },
		{ databaseSortingKey: x => x.acceptedUtc, label: "Accepted" },
		{ databaseSortingKey: null, label: "Details" },
		{ databaseSortingKey: null, label: "Admin note" }
	];

	return (
		<>
			<Box
				display="flex"
				flexDirection="reverse-row"
				alignItems="center"
				flexWrap="nowrap"
				justifyContent="flex-end"
				padding={2}
			>
				<Box>
				</Box>

				<Box flexGrow={1}></Box>
				<Box>
					<FormControl
						fullWidth={true} style={{
							minWidth: "150px"
						}}>
						<InputLabel>
							Shipping States
						</InputLabel>
						<Select
							disabled={!isShippingStateDropdownEnabled || !!searchTextValue}
							native={false}
							value={currentlySelectedShippingState}
							onChange={handleChange}
							fullWidth={true}
							labelWidth={5}
						>
							{props.states.map(state => <MenuItem value={state} key={state}>{state}</MenuItem>)
							}
						</Select>
					</FormControl>
				</Box>
				<Box>
					<TextField
						style={{ marginRight: 8, marginLeft: 8 }}
						label={("Message search")}
						type="text"
						value={searchTextValue}
						onChange={(e) => setSearchTextValue(e.currentTarget.value)}
						disabled={!isSearchable}
					/>
				</Box>
				<Box>
					{renderSelectItemCount(51)}
				</Box>
				<Box>
					<Button
						style={{ marginLeft: 8 }}
						color="primary"
						variant="contained"
						disabled={!isSearchable}
						onClick={submitSearch}
					>
						Submit
					</Button>
				</Box>
			</Box>

			<TabsControl
				selectedTabIndex={currentlySelectedTabIndex}
				onTabChange={onTabChange}
				tabs={[
					{
						label: "Auto updates disabled",
						content: <TableComponent<AdminGetTransactionResponse>
							call={async (options) => await adminClient().adminTransactionsWithAutoUpdatesDisabledGet(options)}
							isSelectable={false}
							sortDirection="desc"
							size={10}
							additionalQueryParameters={{
								isDisabled: true
							}}
							headers={unexpectedShippingStateTransactionHeaders()}
							renderRow={transaction => renderInUnexpectedShippingStateTransactionRow(transaction)}
						/>
					},
					{
						label: "Ready for pickup",
						content:
							<TableComponent
								call={async (options) =>
									await adminClient().adminReadyForPickupShippingTransactionsGet(options)}
								isSelectable={false}
								sortDirection="asc"
								size={10}
								headers={shippingTransactionHeaders}
								renderRow={transaction => renderShippingTransactionRow(transaction)}
							/>
					},
					{
						label: "Delayed",
						content:
							<TableComponent
								call={async (options) =>
									await adminClient().adminDelayedShippingTransactionsGet(options)}
								isSelectable={false}
								dependencies={[
									currentlySelectedShippingState
								]}
								sortDirection="asc"
								size={10}
								headers={delayedTransactionHeaders}
								renderRow={transaction => renderDelayedTransactionRow(transaction)}
							/>
					},
					{
						label: "Shipping",
						content:
							<TableComponent
								call={async (options) =>
									await adminClient().adminTransactionsRecentShippingTransactionsJsonGet({
										...options,
										shippingState: currentlySelectedShippingState
									})}
								isSelectable={false}
								sortDirection="desc"
								size={10}
								dependencies={[
									currentlySelectedShippingState
								]}
								headers={shippingTransactionHeaders}
								renderRow={transaction => renderShippingTransactionRow(transaction)}
							/>
					},
					{
						label: "Bring",
						content:
							<TableComponent<AdminTransactionResponse>
								call={async (options) => !searchTextValue ?
									await adminClient().adminTransactionsBringTransactionsJsonGet({
										...options,
										shippingState: currentlySelectedShippingState
									}) :
									await adminClient().adminTransactionsItemsQueryGet({
										...options,
										query: searchTextValue,
										exactItemCount: currentlySelectedItemCount,
										shippingProviderType: "bring"
									})
								}
								isSelectable={false}
								sortDirection="desc"
								size={10}
								dependencies={[
									currentlySelectedShippingState,
									updateBringTable
								]}
								headers={shippingTransactionHeaders}
								renderRow={transaction => renderShippingTransactionRow(transaction)}
							/>
					},
					{
						label: "Dao Shop",
						content:
							<TableComponent<AdminTransactionResponse>
								call={async (options) => !searchTextValue ?
									await adminClient().adminTransactionsDaoShopTransactionsJsonGet({
										...options,
										shippingState: currentlySelectedShippingState
									}) :
									await adminClient().adminTransactionsItemsQueryGet({
										...options,
										query: searchTextValue,
										exactItemCount: currentlySelectedItemCount,
										shippingProviderType: "daoShop"
									})
								}
								isSelectable={false}
								sortDirection="desc"
								size={10}
								dependencies={[
									currentlySelectedShippingState,
									updateDaoShopTable
								]}
								headers={shippingTransactionHeaders}
								renderRow={transaction => renderShippingTransactionRow(transaction)}
							/>
					},
					{
						label: "Dao Home",
						content:
							<TableComponent<AdminTransactionResponse>
								call={async (options) => !searchTextValue ?
									await adminClient().adminTransactionsDaoHomeTransactionsJsonGet({
										...options,
										shippingState: currentlySelectedShippingState
									}) :
									await adminClient().adminTransactionsItemsQueryGet({
										...options,
										query: searchTextValue,
										exactItemCount: currentlySelectedItemCount,
										shippingProviderType: "daoHome"
									})
								}
								isSelectable={false}
								sortDirection="desc"
								size={10}
								dependencies={[
									currentlySelectedShippingState,
									updateDaoHomeTable
								]}
								headers={shippingTransactionHeaders}
								renderRow={transaction => renderShippingTransactionRow(transaction)}
							/>
					},
					{
						label: "Instabox",
						content:
							<TableComponent<AdminTransactionResponse>
								call={async (options) => !searchTextValue ?
									await adminClient().adminTransactionsInstaboxTransactionsJsonGet({
										...options,
										shippingState: currentlySelectedShippingState
									}) :
									await adminClient().adminTransactionsItemsQueryGet({
										...options,
										query: searchTextValue,
										exactItemCount: currentlySelectedItemCount,
										shippingProviderType: "coolRunnerInstabox"
									})}
								isSelectable={false}
								sortDirection="desc"
								size={10}
								dependencies={
									[
										currentlySelectedShippingState,
										updateInstaBoxTable
									]}
								headers={shippingTransactionHeaders}
								renderRow={transaction => renderShippingTransactionRow(transaction)}
							/>
					},
					{
						label: "Pickup Home",
						content:
							<TableComponent<AdminTransactionResponse>
								call={async (options) =>
									await adminClient().adminTransactionsRecentPickupHomeTransactionsJsonGet(options)}
								isSelectable={false}
								sortDirection="desc"
								size={10}
								headers={pickupTransactionHeaders}
								renderRow={transaction => renderPickupTransactionRow(transaction)}
							/>
					}
				]} />
			<UnexpectedShippingStateConfirmationDialogsComponent
				transactionId={currentlySelectedTransactionId}
				isEnableDialogOpen={isEnableDialogOpen}
				closeEnableDialog={closeEnableDialog}
				isDisableDialogOpen={isDisableDialogOpen}
				closeDisableDialog={closeDisableDialog}
				isRemoveDialogOpen={isRemoveDialogOpen}
				closeRemoveDialog={closeRemoveDialog}
			/>
		</>
	);
}
